import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const ServicesPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout title={siteTitle}>
      <SEO title="Servicios" />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h1 id="heading-level-2">Nuestros Servicios</h1>
          <div className="row">
            <div className="col-demo col-6">
              <GatsbyImage
                image={data.arqDesign.childImageSharp.gatsbyImageData}
                alt="casa representando servicio de diseño"
                className="services-card-image-content" />

              <h3 id="heading-level-3">Diseño Arquitectónico</h3>
              <hr />
              <p>
                Acompañamos a nuestros clientes desde la selección de sus terrenos y análisis de
                factibilidad hasta la construcción y puesta en marcha de sus edificios asegurándonos
                de que el diseño sea tanto atractivo como rentable. Algunos de nuestros servicios
                incluyen:
              </p>
              <ul>
                <li>
                  <strong>Test Fit,</strong> o estudios preliminares de factibilidad.
                </li>
                <li>
                  <strong>Proyecto Arquitectónico,</strong> para edificios nuevos o existentes
                  acompañando al cliente desde el Diseño Conceptual hasta Documentos de
                  Construcción.
                </li>
                <li>
                  <strong>Gerencia de Diseño,</strong> coordinamos los equipos de arquitectura y los
                  consultores de las distintas especialidades involucradas en cada proyecto a lo
                  largo del proceso de diseño con el objetivo de obtener un proyecto integral,
                  eficiente y completo.
                </li>
                <li>
                  <strong>Supervisión Arquitectónica,</strong> nos aseguramos de que la idea
                  plasmada en los planos se refleje con la calidad y detalle planeado en el edificio
                  construido.
                </li>
              </ul>
            </div>
            <div className="col-demo col-6">
              <GatsbyImage
                image={data.sustainabilityConsultancy.childImageSharp.gatsbyImageData}
                alt="Daniela y equipo recibiendo certificado LEED"
                className="services-card-image-content" />
              <h3 id="heading-level-3">Consultoría Sustentable</h3>
              <hr />
              <p>
                Consideramos que un proyecto no sólo debe ser estético, sino pensado para reducir su
                impacto en el medio ambiente, ser cómodo para sus usuarios y rentable para sus
                inversionistas. Para lograrlo, ofrecemos los siguientes servicios:
              </p>
              <ul>
                <li>
                  Análisis Bioclimático, que analiza la optimización del edificio en temas de
                  emplazamiento y diseño pasivo como la orientación ideal, vientos dominantes y
                  asoleamiento entre otros.
                </li>
                <li>
                  Certificación LEED, nuestros profesionales acreditados como LEED AP ofrecen
                  acompañamiento a nuestros clientes para definir las mejores prácticas de diseño y
                  construcción que permitan lograr los objetivos trazados incluyendo la
                  certificación LEED. Igualmente, el LEED AP coordinará a los especialistas
                  necesarios (incluyendo Commissioning Authority, especialista en Modelo Energético
                  y LEED Champion) para asegurar el cumplimiento de los créditos y la certificación.
                </li>
                <li>
                  Certificación Edge, contamos también con profesionales con la credencial de Edge
                  Expert, quienes poseen los conocimientos necesarios para lograr la certificación
                  Edge de cualquier tipología de edificio.
                </li>
              </ul>
            </div>
          </div>
          <h2>Conoce más</h2>
          <p>
            <a href="./contact">Déjanos un mensaje</a> y con gusto comenzamos a apoyarte con
            cualquiera de nuestros servicios.
          </p>
          {/* <figure className="kg-card kg-image-card">
            <Img fluid={data.smallPic.childImageSharp.fluid} className="kg-image" />
            <figcaption>Some image</figcaption>
          </figure> */}
        </div>
      </article>
    </Layout>
  );
};

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
    }
  }
  arqDesign: file(relativePath: {eq: "disenoArquitectonico.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED) 
      # or FULL_WIDTH
    }
  }
  sustainabilityConsultancy: file(relativePath: {eq: "consultoriaSustentable.jpeg"}) {
    childImageSharp {
      gatsbyImageData(layout: CONSTRAINED)
    }
  }
  smallPic: file(relativePath: {eq: "fabio-comparelli-696506-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  medPic: file(relativePath: {eq: "sophia-valkova-30139-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  largePic: file(relativePath: {eq: "vladimir-malyutin-98174-unsplash.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => <ServicesPage location={props.location} data={data} {...props} />}
  />
);
